import React, { useEffect, useRef, useState } from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Stack,
  Tooltip,
  Grid,
} from "@mui/material";

import { Modal, Image, Button, ConfigProvider } from "antd";
// project import
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"; // assets
import { useNavigate } from "react-router";
import InstallMobileOutlinedIcon from "@mui/icons-material/InstallMobileOutlined";
import svg from "../../../../assets/images/wave.png";
import QR from "../../../../assets/images/QR.png";
import { DownloadOutlined } from "@ant-design/icons";
import { TinyColor } from "@ctrl/tinycolor";

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const GetMobileApp = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const anchorRef = useRef(null);

  //   ------MODAL---------------
  const [isModalOpen, set_isModalOpen] = useState(false);
  let colors1 = ["#2B6DBD", "#15CBFC"];

  const getHoverColors = (colors) =>
    colors.map((color) => new TinyColor(color).lighten(5).toString());

  const getActiveColors = (colors) =>
    colors.map((color) => new TinyColor(color).darken(5).toString());
  return (
    <>
      <Box sx={{ flexShrink: 0, }}>
        <Tooltip title="Get Mobile App">
          <IconButton
            disableRipple
            color="secondary"
            sx={{
              color: "text.primary",
              bgcolor: "grey.100",
            }}
            aria-label="download mobile app"
            ref={anchorRef}
            aria-haspopup="true"
            onClick={() => {
              set_isModalOpen(!isModalOpen);
            }}
          >
            <Badge color="primary">
              <InstallMobileOutlinedIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: `linear-gradient(135deg, ${colors1.join(", ")})`,
              colorPrimaryHover: `linear-gradient(135deg, ${getHoverColors(
                colors1
              ).join(", ")})`,
              colorPrimaryActive: `linear-gradient(135deg, ${getActiveColors(
                colors1
              ).join(", ")})`,
              lineWidth: 0,
            },
            Modal: {
              wireframe: true,
            },
          },
          token: {
            paddingLG: 0,
          },
        }}
      >
        <Modal
          open={isModalOpen}
          onCancel={() => {
            set_isModalOpen(false);
          }}
          footer={null}
          width={700}
          closeIcon={null}
          style={{
            padding: 0,
            backgroundColor: "#3D81F3",
          }}
          //   centered={true}
        >
          <Grid
            container
            sx={{
              height: "100%",
              backgroundImage: `url(${svg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#3D81F3",
              p: 6,
            }}
            spacing={2}
          >
            <Grid item lg={8}>
              <Stack
                justifyContent="center"
                spacing={2}
                sx={{ height: "100%" }}
              >
                <Typography color="white" variant="h1" component="h2">
                  Download Our App
                </Typography>
                <Typography color="white" variant="h4">
                  Get the latest version of our app for your device.
                </Typography>
                <Stack direction="row" justifyContent="start">
                  <Button
                    type="primary"
                    size="large"
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      window.open(
                        "https://test-kr9948.s3.us-west-1.amazonaws.com/Maintenance8May2+1.apk",
                        "_blank"
                      );
                    }}
                  >
                    Download Now
                  </Button>
                </Stack>
              </Stack>
            </Grid>
            <Grid item lg={4}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%", width: "100%" }}
              >
                <Image
                  width={200}
                  style={{
                    margin: 0,
                  }}
                  src={QR}
                  preview={false}
                />
              </Stack>
            </Grid>
          </Grid>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default GetMobileApp;
