import React, { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Stack,
  Tooltip,
} from "@mui/material";
import { Button, Popover, ConfigProvider, Table } from "antd";
// project import
import { useNavigate } from "react-router";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  get_api_function,
  get_pending_ticket_timing,
  get_spare_part_name,
} from "utils/api";
import dayjs from "dayjs";

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Timer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [timer_data, set_timer_data] = useState([]);
  const [open, set_open] = useState(false);

  useEffect(() => {
    open &&
      get_api_function("/pm/get_pending_ticket_timing", (res) => {
        set_timer_data(res?.data?.data);
      });
  }, [open]);

  function timeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString?.split(":")?.map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }

  function getTimeDifference(startDate, endDate) {
    const timeDifference = Math.abs(startDate - endDate);
    return Math.floor(timeDifference / 1000);
  }
  function incrementTime(elapsedSeconds) {
    const hours = Math.floor(elapsedSeconds / 3600);
    const minutes = Math.floor((elapsedSeconds % 3600) / 60);
    const seconds = elapsedSeconds % 60;
    const padWithZero = (num) => (num < 10 ? "0" + num : num);

    return `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(
      seconds
    )}`;
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update time values in the local state
      set_timer_data((prevData) => {
        return prevData?.map((item) => {
          let elapsedSeconds =
            timeToSeconds(
              item?.formatted_sum === "" ? "00:00:00" : item?.formatted_sum
            ) +
            getTimeDifference(
              dayjs.utc().add(5, "hour").add(30, "minute"),
              dayjs(item?.last_start_time)
            );
          return {
            ...item,
            time: incrementTime(elapsedSeconds),
          };
        });
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Popover: {
            zIndexPopup: 3000,
          },
        },
      }}
    >
      <Popover
        content={
          <Table
            dataSource={timer_data}
            columns={[
              {
                dataIndex: "ticket_unique_id",
                title: "Ticket Id",
                align: "center",
              },
              {
                dataIndex: "time",
                title: "Time",
                align: "center",
              },
              {
                title: "Action",
                align: "center",
                render: (_, item) => {
                  return (
                    <IconButton
                      style={{ color: "red" }}
                      onClick={() => {
                        if (item?.issue_type === "Maintenance") {
                          navigate("/maintenance-data", {
                            state: {
                              ticket_id: item?.ticket_id,
                              machine_id: item?.machine_id,
                            },
                          });
                        } else if (
                          item?.issue_type === "Breakdown" &&
                          item?.machine_id
                        ) {
                          navigate("/break-down", {
                            state: {
                              ticket_id: item?.ticket_id,
                              machine_id: item?.machine_id,
                            },
                          });
                        } else if (item?.issue_type === "Insurance Policy Renewal") {
                          navigate("/insurance-ticket", {
                            state: {
                              ticket_id: item?.ticket_id,
                            },
                          });
                        }  else {
                          navigate("/ticket-details", {
                            state: {
                              ticket_id: item.ticket_unique_id?.split("-")[1],
                            },
                          });
                        }
                      }}
                    >
                      <VisibilityIcon style={{ color: "#00c853" }} />
                    </IconButton>
                  );
                },
              },
            ]}
            rowKey="ticket_id"
            scroll={{ y: 500 }}
            size="small"
            bordered
            pagination={false}
            style={{
              width: "400px",
            }}
          />
        }
        title="Ongoing Tickets"
        trigger="click"
        open={open}
        onOpenChange={(value) => set_open(value)}
        style={{
          width: "400px",
        }}
      >
        <Box sx={{ flexShrink: 0}}>
          <IconButton
            disableRipple
            color="secondary"
            sx={{
              color: "text.primary",
              bgcolor: "grey.100",
            }}
            aria-label="open Timer"
            ref={anchorRef}
            aria-haspopup="true"
          >
            <Badge color="primary">
              <AccessTimeIcon />
            </Badge>
          </IconButton>
        </Box>
      </Popover>
    </ConfigProvider>
  );
};

export default Timer;
