import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  IconButton,
  Toolbar,
  useMediaQuery,
  Stack,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// project import
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";
import { useNavigate } from "react-router-dom";
import { Select, ConfigProvider } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { get_api_function } from "utils/api";

import { useDispatch, useSelector } from "react-redux";
// assets
// import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const labels = useSelector((state) => state.ModuleLables);
  const [industrialPlants, set_industrialPlants] = useState([]);
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  const iconBackColor = "grey.100";
  const iconBackColorOpen = "grey.200";

  useEffect(() => {
    get_api_function("/get_pm_category", (res) => {
      if (res.data.status === "success")
        set_industrialPlants(res?.data?.category_data);
    });
  }, []);

  // common header
  const mainHeader = (
    <Toolbar>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ width: "100%" }}
      >
        {matchDownMD && (
          <IconButton
            disableRipple
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            color="secondary"
            sx={{
              color: "text.primary",
              bgcolor: open ? iconBackColorOpen : iconBackColor,
            }}
          >
            {open ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          </IconButton>
        )}
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={() => {
            navigate("/modules");
          }}
          edge="start"
          color="secondary"
          sx={{
            color: "text.primary",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
          }}
        >
          <HomeOutlinedIcon />
        </IconButton>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                zIndexPopup: 1201,
              },
            },
          }}
        >
          <Select
            showSearch
            id="category"
            // allowClear
            size="large"
            placeholder="Select Category"
            labelRender={(props) => {
              return props?.label?.props?.children;
            }}
            optionFilterProp="children"
            value={localStorage.getItem("MainModule")}
            onChange={(value, item) => {
              localStorage.setItem("MainModule", value);
              localStorage.setItem("MainModuleID", item?.key);
              localStorage.setItem(
                "category_labels",
                JSON.stringify(labels[value] || labels["Other"])
              );
              window.location.reload();
            }}
            filterOption={(input, option) =>
              (option?.label2 ?? "").toLowerCase().includes(input.toLowerCase())
            }
            bordered={false}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
              width: "fit-content",
            }}
            options={industrialPlants
              ?.filter((item) => item?.category_name !== "Other")
              ?.map((item) => {
                return {
                  value: item?.category_name,
                  label: (
                    <div
                      style={{
                        maxWidth: "100%",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {item?.category_name}
                    </div>
                  ),
                  label2: item?.category_name,
                  key: item?.category_id,
                };
              })}
          />
        </ConfigProvider>
        <Box sx={{ width: "100%" }}></Box>
        <HeaderContent />
      </Stack>
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow:
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
    },
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default Header;
