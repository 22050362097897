import { createSlice } from "@reduxjs/toolkit";

const eChartsColorsSlice = createSlice({
  name: "eChartsColors",
  initialState: [
    "#008FFB",
    "#00E396",
    "#FEB019",
    "#FF4560",
    "#775DD0",
    "#FFA500",
    "#4CAF50",
    "#9C27B0",
    "#FF5722",
    "#7D02EB",
  ],
  reducers: {
    set_eChartsColors: (state, action) => {
      return action.payload;
    },
  },
});

export const { set_eChartsColors } = eChartsColorsSlice.actions;
export default eChartsColorsSlice.reducer;
