import React, { useState } from "react";
import {
  IconButton,
  Tooltip,
  Badge,
  List,
  ListItem,
  ListItemText,
  Stack,
  Box,
} from "@mui/material";
import { Button, Popover, ConfigProvider, Dropdown } from "antd";

import TranslateIcon from "@mui/icons-material/Translate";

const LanguageSelect = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang")
  );

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen((prev) => !prev);
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    localStorage.setItem("lang", language);
    setOpen(false);
    window.location.reload();
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Dropdown: {
            zIndexPopup: 3000,
          },
        },
      }}
    >
      <Dropdown
        menu={{
          items: [
            {
              label: "English",
              key: "en",
            },
            {
              label: "हिंदी",
              key: "hi",
            },
            {
              label: "मराठी",
              key: "mr",
            },
            // {
            //   label: "ಕನ್ನಡ",
            //   key: "kn",
            // },
          ],
          selectable: true,
          selectedKeys: [selectedLanguage],
          onClick: (e) => handleLanguageSelect(e?.key),
        }}
        trigger={["click"]}
        arrow
        placement="bottom" 
      >
        <Box sx={{ flexShrink: 0, }}>
          <IconButton
            disableRipple
            color="secondary"
            sx={{
              color: "text.primary",
              bgcolor: "grey.100",
            }}
            aria-label="open calender"
            aria-haspopup="true"
            onClick={handleOpenChange}
          >
            <Badge color="primary">
              <TranslateIcon />
            </Badge>
          </IconButton>
        </Box>
      </Dropdown>
    </ConfigProvider>
  );
};

export default LanguageSelect;
