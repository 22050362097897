import React, { useState, useEffect } from "react";

// import { DashboardOutlined } from '@ant-design/icons';
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
// assets
import { ControlOutlined } from "@ant-design/icons";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsInputSvideoOutlinedIcon from "@mui/icons-material/SettingsInputSvideoOutlined";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ChromeOutlined, QuestionOutlined } from "@ant-design/icons";
import FmdGoodIcon from "@mui/icons-material/FmdGoodOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import TuneIcon from "@mui/icons-material/Tune";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import ChecklistIcon from "@mui/icons-material/Checklist";
import FactoryIcon from "@mui/icons-material/FactoryOutlined";
import { useTranslation } from "react-i18next";

const icons = {
  DashboardOutlinedIcon,
  CasinoOutlinedIcon,
  ConfirmationNumberOutlinedIcon,
  CategoryOutlinedIcon,
  PersonOutlineIcon,
  AssignmentTurnedInOutlinedIcon,
  AssignmentIcon,
  SchemaOutlinedIcon,
  SettingsInputSvideoOutlinedIcon,
  PersonOutlineOutlinedIcon,
  ControlOutlined,
  PermDataSettingIcon,
  SettingsOutlinedIcon,
  ChromeOutlined,
  FmdGoodIcon,
  QuestionOutlined,
  GroupsIcon,
  TuneIcon,
  SupervisorAccountIcon,
  HowToRegIcon,
  ManageAccountsIcon,
  KeyboardDoubleArrowRightIcon,
  ViewQuiltOutlinedIcon,
  TimelineOutlinedIcon,
  ChecklistIcon,
  FactoryIcon,
};

// ==============================|| MENU ITEMS ||============================== //

function Dashboard() {
  const [access_list_data, setaccess_list_data] = useState([]);
  const [MAIN_MODULE, SET_MAIN_MODULE] = useState("");
  const [refresh, set_refresh] = useState([]);
  const [refresh2, set_refresh2] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const storedData = localStorage.getItem("access_list_data");
    if (storedData) {
      setaccess_list_data(JSON.parse(storedData));
    } else {
      set_refresh(!refresh);
    }
  }, [refresh]);

  useEffect(() => {
    const main_module = localStorage.getItem("MainModule");
    if (main_module) {
      SET_MAIN_MODULE(main_module);
    } else {
      set_refresh2(!refresh2);
    }
  }, [refresh2]);

  return [
    MAIN_MODULE !== "Configuration"
      ? {
          id: "group-dashboard",
          title: "Menu",
          type: "group",
          children: [
            {
              id: "dashboard",
              title: t("Dashboard"),
              type: "item",
              url: "/dashboard",
              icon: icons.DashboardOutlinedIcon,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Dashboard"
              )?.read_access,
            },
            {
              id: "",
              title: "Notifications",
              type: "item",
              url: "/notifications",
              icon: icons.DashboardOutlinedIcon,
              breadcrumbs: true,
              showMenu: false,
            },
            {
              id: "",
              title: "Calendar",
              type: "item",
              url: "/calendar",
              icon: icons.DashboardOutlinedIcon,
              breadcrumbs: true,
              showMenu: false,
            },
            {
              id: "tickets",
              title: t("Tickets"),
              type: "item",
              url: "/tickets",
              icon: icons.ConfirmationNumberOutlinedIcon,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Tickets"
              )?.read_access,
            },
            {
              id: "checklist",
              title: t("Checklist"),
              type: "item",
              url: "/checklist",
              icon: icons.ChecklistIcon,
              breadcrumbs: true,
              showMenu: true,
              // showMenu: access_list_data?.find(
              //   (item) => item.module_name === "Checklist"
              // )?.read_access,
            },
            {
              id: "Asset Management",
              title: t("Asset Management"),
              type: "collapse",
              icon: icons.CategoryOutlinedIcon,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Asset Management"
              )?.read_access,
              children: [
                {
                  id: "machine",
                  title: t("Assets"),
                  type: "item",
                  url: "/asset",
                  icon: icons.CategoryOutlinedIcon,
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Assets"
                  )?.read_access,
                },
                {
                  id: "analytics",
                  title: t("Analytics"),
                  type: "item",
                  icon: icons.TimelineOutlinedIcon,
                  url: "/analytics",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Analytics"
                  )?.read_access,
                },
                {
                  id: "asset-analytics2",
                  title: t("Asset Analytics"),
                  type: "item",
                  icon: icons.TimelineOutlinedIcon,
                  url: "/asset-analytic",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Asset Analytics"
                  )?.read_access,
                },
                localStorage.getItem("MainModule") === "Vehicles" && {
                  id: "vehicle-analysis",
                  title: t("Vehicle Analytics"),
                  type: "item",
                  icon: icons.TimelineOutlinedIcon,
                  url: "/vehicle-analytics",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Asset Analytics"
                  )?.read_access,
                },
                localStorage.getItem("MainModule") === "Machines" && {
                  id: "machine-analysis",
                  title: t("Machine Analytics"),
                  type: "item",
                  icon: icons.TimelineOutlinedIcon,
                  url: "/machine-analytics",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Asset Analytics"
                  )?.read_access,
                },
              ]?.filter(Boolean),
            },

            {
              id: "spare_management",
              title: t("Spare Management"),
              type: "collapse",
              icon: icons.ViewQuiltOutlinedIcon,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Spare Management"
              )?.read_access,
              children: [
                {
                  id: "spare",
                  title: t("Spares"),
                  type: "item",
                  url: "/spare-parts",
                  icon: icons.ViewQuiltOutlinedIcon,
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Spares"
                  )?.read_access,
                },
                {
                  id: "inward",
                  title: t("Inward"),
                  type: "item",
                  icon: icons.KeyboardDoubleArrowRightIcon,
                  url: "/spare-inward",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Inward"
                  )?.read_access,
                },
                {
                  id: "spare-analytics",
                  title: t("Analytics"),
                  type: "item",
                  icon: icons.TimelineOutlinedIcon,
                  url: "/spare-analytics",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Spare Analytics"
                  )?.read_access,
                },
              ],
            },
            {
              id: "machine",
              title: "Add Asset",
              type: "item",
              url: "/add-asset",
              icon: icons.DashboardOutlinedIcon,
              breadcrumbs: true,
              showMenu: false,
            },
            {
              id: "report",
              title: "Report",
              type: "item",
              url: "/report",
              icon: icons.AssignmentIcon,
              breadcrumbs: true,
              showMenu: false,
            },

            {
              id: "tickets",
              title: "Ticket Details",
              type: "item",
              url: "/ticket-details",
              icon: icons.ConfirmationNumberOutlinedIcon,
              breadcrumbs: true,
              showMenu: false,
            },
            {
              id: "tickets",
              title: "Create Ticket",
              type: "item",
              url: "/create-ticket",
              icon: icons.ConfirmationNumberOutlinedIcon,
              breadcrumbs: true,
              showMenu: false,
            },
            {
              id: "tickets",
              title: "Create Ticket",
              type: "item",
              url: "/maintenance-data",
              icon: icons.ConfirmationNumberOutlinedIcon,
              breadcrumbs: true,
              showMenu: false,
            },
            {
              id: "tickets",
              title: "Create Ticket",
              type: "item",
              url: "/break-down",
              icon: icons.ConfirmationNumberOutlinedIcon,
              breadcrumbs: true,
              showMenu: false,
            },
            {
              id: "",
              title: "User Profile",
              type: "item",
              url: "/user-profile",
              icon: icons.ConfirmationNumberOutlinedIcon,
              breadcrumbs: true,
              showMenu: false,
            },
            {
              id: "Visitor_Management",
              title: t("Visitor Management"),
              type: "collapse",
              icon: icons.ManageAccountsIcon,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Visitor Management"
              )?.read_access,
              children: [
                {
                  id: "vendorConfig",
                  title: t("Visitor Details"),
                  type: "item",
                  icon: icons.SupervisorAccountIcon,
                  url: "/visitor-details",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Visitor Details"
                  )?.read_access,
                },
                {
                  id: "vendorApproval",
                  title: t("Visitor Approvals"),
                  type: "item",
                  icon: icons.HowToRegIcon,
                  url: "/approveVendor",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Visitor Approvals"
                  )?.read_access,
                },
              ],
            },
            {
              id: "settings",
              title: t("Settings"),
              type: "collapse",
              icon: icons.TuneIcon,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Settings"
              )?.read_access,
              children: [
                {
                  id: "ticketFlow",
                  title: t("Ticket Work Flow"),
                  type: "item",
                  icon: icons.SchemaOutlinedIcon,
                  url: "/ticket-work-flow",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Ticket Work Flow"
                  )?.read_access,
                },

                {
                  id: "vendor-config",
                  title: t("Vendors"),
                  type: "item",
                  icon: icons.FactoryIcon,
                  url: "/vendor-configuration",
                  breadcrumbs: true,
                  showMenu: true,
                  // showMenu: access_list_data?.find(
                  //   (item) => item.module_name === "Location Configuration"
                  // )?.read_access,
                },
              ],
            },
          ],
        }
      : {
          id: "configuration",
          title: "Configuration",
          type: "group",
          children: [
            {
              id: "settings",
              title: t("Settings"),
              type: "collapse",
              icon: icons.TuneIcon,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Settings"
              )?.read_access,
              children: [
                {
                  id: "ticketFlow",
                  title: t("Ticket Work Flow"),
                  type: "item",
                  icon: icons.SchemaOutlinedIcon,
                  url: "/ticket-work-flow",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Ticket Work Flow"
                  )?.read_access,
                },
                {
                  id: "userConfig",
                  title: t("Users"),
                  type: "item",
                  icon: icons.PersonOutlineOutlinedIcon,
                  url: "/user-configuration",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "User Configuration"
                  )?.read_access,
                },
                {
                  id: "AddLocation",
                  title: t("Locations"),
                  type: "item",
                  icon: icons.FmdGoodIcon,
                  url: "/location-configuration",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Location Configuration"
                  )?.read_access,
                },
                {
                  id: "vendor-config",
                  title: t("Vendors"),
                  type: "item",
                  icon: icons.FactoryIcon,
                  url: "/vendor-configuration",
                  breadcrumbs: true,
                  showMenu: true,
                  // showMenu: access_list_data?.find(
                  //   (item) => item.module_name === "Location Configuration"
                  // )?.read_access,
                },
                // {
                //   id: "spare_config",
                //   title: "Spare Configuration",
                //   type: "item",
                //   icon: icons.FmdGoodIcon,
                //   url: "/spare-configuration",
                //   breadcrumbs: true,
                //   showMenu: true,
                // },
                // {
                //   id: "asset_config",
                //   title: "Asset Configuration",
                //   type: "item",
                //   icon: icons.FmdGoodIcon,
                //   url: "/asset-configuration",
                //   breadcrumbs: true,
                //   showMenu: true,
                // },
                {
                  id: "designation-hierarchy",
                  title: t("Designation Hierarchy"),
                  type: "item",
                  url: "/designation-hierarchy",
                  icon: icons.ChromeOutlined,
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Designation Hierarchy"
                  )?.read_access,
                },
                {
                  id: "accessControl",
                  title: t("Access Control"),
                  type: "item",
                  icon: icons.ControlOutlined,
                  url: "/access-control",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Access Control"
                  )?.read_access,
                },
                {
                  id: "Comapny Details",
                  title: t("Company Details"),
                  type: "item",
                  icon: icons.GroupsIcon,
                  url: "/company-profile",
                  breadcrumbs: true,
                  showMenu: access_list_data?.find(
                    (item) => item.module_name === "Company Details"
                  )?.read_access,
                },
              ],
            },
          ],
        },

    {
      id: "support",
      title: t("Support"),
      type: "group",
      children: [
        {
          id: "documentation",
          title: t("Documentation"),
          type: "item",
          url: "https://nexgensis-1.gitbook.io/maintenance-web-application/",
          icon: icons.QuestionOutlined,
          external: true,
          target: true,
          showMenu: true,
        },
        // {
        //   id: "demo_flow",
        //   title: "Demo Ticket Work Flow",
        //   type: "item",
        //   icon: icons.SchemaOutlinedIcon,
        //   url: "/ticket-work-flow2",
        //   breadcrumbs: true,
        //   showMenu: true,
        // },
      ],
    },
  ].filter(Boolean);
}

export default Dashboard;
