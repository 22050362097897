import React, { useEffect, useState } from "react";
import { Timeline, Image, Descriptions, Drawer as Drawer1 } from "antd";
import MainCard from "../../../components/MainCard";
import {
  Typography,
  Stack,
  Button,
  MenuItem,
  OutlinedInput,
  Grid,
  Select,
  InputLabel,
  Drawer,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import {
  cost_submit,
  genarate_pdf_for_ticket,
  get_cost_details,
  get_flow_details,
} from "utils/api";
import {
  update_ticket_status,
  update_workflow_engin,
  get_employees_for_assign,
  get_instruction_status,
  return_ticket,
  reopen_ticket,
} from "utils/api";
import { toast } from "react-toastify";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleDownloadbase64ToFile } from "components/Base64toFile";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import {
  MessageList,
  Input,
  Button as MessageButton,
} from "react-chat-elements";
import InsuranceTicketTable from "./InsuranceTicketTable";
import TimeLineData from "../ticket-details/TimeLine";
import ActionInTicket from "../ticket-details/ActionInTicket";
import CostInvolved from "../ticket-details/CostInvolved";
import ChatBox from "../ticket-details/ChatBox";

const InsuranceTicket = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const location = useLocation();
  const history_ticket_id = location?.state?.ticket_id
    ? location?.state?.ticket_id
    : localStorage.getItem("history_ticket_id");
  const [all_data, set_all_data] = useState(null);
  const success_get_flow_details = (res) => {
    set_all_data(res?.data?.data[0]);
  };

  const success_genarate_pdf_for_ticket = (res) => {
    handleDownloadbase64ToFile(res.file, `${history_ticket_id}`);
    toast.success(res.message);
  };

  useEffect(() => {
    if (history_ticket_id) {
      get_flow_details(success_get_flow_details, history_ticket_id);
    }
  }, [history_ticket_id]);

  const mainDetails = () => {
    if (all_data && all_data.Main_details) {
      return all_data.Main_details[0];
    }
    return;
  };

  const main_details = mainDetails();

  useEffect(() => {
    get_instruction_status(success_set_instructions_list, history_ticket_id);
  }, [history_ticket_id]);

  const [instrutions_list, set_instructions_list] = useState([]);

  const success_set_instructions_list = (res) => {
    set_instructions_list(res.data.data);
  };

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [tableDrawer, settableDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    settableDrawer(false);
  };
  const [open, setOpen] = useState(false);

  // -------cost

  return (
    <>
      <Tooltip title="Support">
        <FloatButton
          icon={<QuestionCircleOutlined />}
          type="primary"
          style={{
            right: 24,
          }}
          onClick={() => setOpen(!open)}
        />
      </Tooltip>

      <Grid container>
        <Grid item xs={12} lg={9}>
          {matchDownSM && (
            <Stack
              direction="row"
              spacing={1}
              justifyContent="end"
              sx={{ m: 2 }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  settableDrawer(true);
                }}
              >
                Cost
              </Button>

              {instrutions_list?.length > 0 && (
                <Button variant="contained" onClick={handleDrawerOpen}>
                  Actions
                </Button>
              )}
            </Stack>
          )}
          <MainCard title="Ticket History" backbutton>
            <Stack spacing={2}>
              <Descriptions
                column={1}
                size="middle"
                contentStyle={{
                  width: "65%",
                }}
                labelStyle={{
                  fontWeight: "800",
                  width: "35%",
                }}
                bordered
                items={[
                  {
                    key: 1,
                    label: "Title",
                    children: main_details?.Summary,
                  },
                  {
                    key: 2,
                    label: "Ticket Id",
                    children: all_data?.ticket_unique_id,
                  },
                  {
                    key: 3,
                    label: "Priority",
                    children: main_details?.priority_level,
                  },
                  {
                    key: 4,
                    label: "Date",
                    children: main_details?.date?.slice(0, 19),
                  },
                  {
                    key: 5,
                    label: "Issue Type",
                    children: main_details?.issue_type,
                  },
                  {
                    key: 6,
                    label: "Created By",
                    children: all_data?.created_by,
                  },
                  {
                    key: 7,
                    label: "Location",
                    children: main_details?.location,
                  },
                  {
                    key: 8,
                    label: "Description",
                    children: main_details?.description,
                  },
                  {
                    key: 9,
                    label: "Ticket History",
                    children: (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          genarate_pdf_for_ticket(
                            success_genarate_pdf_for_ticket,
                            history_ticket_id
                          );
                        }}
                        endIcon={<FileDownloadIcon />}
                      >
                        Report
                      </Button>
                    ),
                  },
                ]}
              />
              {(main_details?.ticket_status === "Completed" ||
                main_details?.ticket_status === "Closed" ||
                main_details?.ticket_status === "Verified" ||
                main_details?.ticket_status === "Resolved" ||
                instrutions_list?.some(
                  (instruction) => instruction.instruction_name === "Completed"
                )) && (
                <InsuranceTicketTable
                  ticket_id={history_ticket_id}
                  show_submit={instrutions_list?.some(
                    (instruction) =>
                      instruction.instruction_name === "Completed"
                  )}
                />
              )}
              <TimeLineData flow_data={all_data?.Flow1 ? all_data.Flow1 : []} />
            </Stack>
          </MainCard>
        </Grid>

        {!matchDownSM && (
          <Grid item xs={12} lg={3}>
            {instrutions_list?.length > 0 &&
              !instrutions_list?.some(
                (instruction) => instruction.instruction_name === "Completed"
              ) && (
                <MainCard title="Actions">
                  <ActionInTicket
                    transaction_id={all_data?.transaction_id}
                    instrutions_list={instrutions_list}
                  />
                </MainCard>
              )}
            <MainCard>
              <CostInvolved history_ticket_id={history_ticket_id} />
            </MainCard>
          </Grid>
        )}

        <Drawer anchor="bottom" open={tableDrawer} onClose={handleDrawerClose}>
          <CostInvolved history_ticket_id={history_ticket_id} />
        </Drawer>

        <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleDrawerClose}>
          <Grid sx={{ p: 5, pb: 12 }}>
            {instrutions_list?.length > 0 && <ActionInTicket />}
          </Grid>
        </Drawer>
      </Grid>
      <Drawer1
        title={`Support`}
        placement="right"
        width={500}
        onClose={() => setOpen(false)}
        open={open}
        zIndex={2147}
      >
        <ChatBox
          params={{
            asset_type: "A",
            asset_model: "A",
            asset_make: "A",
            issue_type: main_details?.issue_type,
          }}
        />
      </Drawer1>
    </>
  );
};

export default InsuranceTicket;
