import React, { useEffect, useState } from "react";

import MainCard from "../../../components/MainCard";
import {
  Typography,
  Stack,
  Button,
  MenuItem,
  OutlinedInput,
  Grid,
  Select,
  InputLabel,
  FormHelperText,
  IconButton,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  add_breakdown_nature,
  add_breakdown_type,
  add_machine_breakdown_details,
  get_all_machineDetails,
  get_breakdown_nature,
  get_breakdown_type,
  get_flow_details,
  get_instruction_status,
  get_machine_breakdown_details,
  get_problem_solution,
  get_spare_details_by_machine,
  get_spare_part_inventory,
  save_machine_time_record,
} from "utils/api";
import { toast } from "react-toastify";
import { Modal, Table, Descriptions, Popover } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Timer from "../StopWatch";
import CostInvolved from "../ticket-details/CostInvolved";
import ActionInTicket from "../ticket-details/ActionInTicket";
import TimeLineData from "../ticket-details/TimeLine";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import { Drawer } from "antd";
import ChatBox from "../ticket-details/ChatBox";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

function BreakdownData() {
  const [label_names, set_label_names] = useState(
    JSON.parse(localStorage.getItem("category_labels"))
  );
  const use_location = useLocation();
  const navigate = useNavigate();

  const machine_id = use_location?.state?.machine_id;
  const ticket_id = use_location?.state?.ticket_id;
  const [transaction_id, set_transaction_id] = useState("");

  const [machine_details, set_machine_details] = useState([]);
  const [ticket_flow, set_ticket_flow] = useState([]);
  const [ticket_details, set_ticket_details] = useState(null);
  const [unique_id, set_unique_id] = useState("");
  const [problemsList, set_problemsList] = useState([]);

  //------------------------------------------------------------------------
  useEffect(() => {
    get_all_machineDetails(success_get_machineDetails, {
      machine_id: machine_id,
    });
    get_breakdown_nature(success_get_breakdown_nature);
    get_breakdown_type(success_get_breakdown_type);
    get_spare_details_by_machine(success_get_spare_part_inventory, {
      machine_id: machine_id,
    });
    get_flow_details(success_get_flow_details, ticket_id);
    get_machine_breakdown_details(success_get_machine_breakdown_details, {
      ticket_id: ticket_id,
    });
    get_instruction_status(success_set_instructions_list, ticket_id);
    get_problem_solution(success_get_machine_problem_list, {
      machine_id: machine_id,
    });
  }, [machine_id, ticket_id]);

  const success_get_flow_details = (res) => {
    if (res.data.status === "success") {
      set_ticket_details(res?.data?.data[0].Main_details[0]);
      set_ticket_flow(res?.data?.data[0].Flow1);
      set_transaction_id(res?.data?.data[0].transaction_id);
      set_unique_id(res?.data?.data[0].ticket_unique_id);
    }
  };

  const success_get_machineDetails = (res) => {
    if (res?.data?.status === "success")
      set_machine_details(res?.data?.data[0]);
  };
  const success_get_machine_problem_list = (res) => {
    set_problemsList(res.data.data);
  };

  const [instrutions_list, set_instructions_list] = useState([]);

  const success_set_instructions_list = (res) => {
    set_instructions_list(res.data.data);
  };

  const [show_table, set_show_table] = useState("");
  const [breakdown_type_list, set_breakdown_type_list] = useState([]);
  const [breakdown_nature_list, set_breakdown_nature_list] = useState([]);
  const [spare_parts_list, set_spare_parts_list] = useState([]);
  const [breakdown_details, set_breakdown_details] = useState();
  const [spare_use_details, set_spare_use_details] = useState([]);

  const success_get_breakdown_nature = (res) => {
    set_breakdown_nature_list(res.data.data);
  };
  const success_get_breakdown_type = (res) => {
    set_breakdown_type_list(res.data.data);
  };
  const success_get_spare_part_inventory = (res) => {
    set_spare_parts_list(res.data.data);
  };
  const success_get_machine_breakdown_details = (res) => {
    set_breakdown_details(res?.data?.data[0]?.breakdown_details[0]);
    set_spare_use_details(res?.data?.data[0]?.spare_details);
    set_show_table(res.data?.data[0]?.has_breakdown_details);
  };

  const [breakdown_type, set_breakdown_type] = useState("");
  const [breakdown_nature, set_breakdown_nature] = useState("");
  const [spare_use, set_spare_use] = useState([]);
  const [tool_use, set_tool_use] = useState("");
  const [horizontal_deployment, set_horizontal_deployment] = useState("");
  const [action_taken_perm, set_action_taken_perm] = useState("");
  const [action_taken_temp, set_action_taken_temp] = useState("");
  const [root_cause, set_root_cause] = useState("");

  const handle_breakdown_type = (e) => {
    set_breakdown_type(e.target.value);
  };
  const handle_breakdown_nature = (e) => {
    set_breakdown_nature(e.target.value);
  };
  const handle_spare_use = (e) => {
    e.preventDefault();
    const temp = [...spare_use];
    temp.push({
      spare_part_id: spare_part,
      quantity: quantity,
    });
    set_spare_use(temp);
    handleCancel();
  };

  const handle_tool_use = (e) => {
    set_tool_use(e.target.value);
  };

  const handle_root_cause = (e) => {
    set_root_cause(e.target.value);
  };

  const handle_action_taken_temp = (e) => {
    set_action_taken_temp(e.target.value);
  };

  const handle_action_taken_perm = (e) => {
    set_action_taken_perm(e.target.value);
  };

  const handle_horizontal_deployment = (e) => {
    set_horizontal_deployment(e.target.value);
  };

  //   ---------------------------------------------------------------------

  const [spare_part, set_spare_part] = useState("");
  const [quantity, set_quantity] = useState("");
  const handle_spare_part = (e) => {
    set_spare_part(e.target.value);
  };
  const [error, set_error] = useState(false);
  const handle_quantity = (e) => {
    if (
      e.target.value <=
      spare_parts_list?.find((item) => item.spare_part_id === spare_part)
        ?.spare_quantity
    ) {
      if (parseInt(e.target.value) === 0) {
        set_error("Quantity cannot be 0");
      } else {
        set_quantity(e.target.value);
        set_error("");
      }
    } else {
      set_error("Quantity exceeds available inventory spare quantity");
    }
  };

  //   -------------------------------------------------------------------------

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //   -------------------------------------add modal--------------------------------

  const [add_index, set_add_index] = useState("");
  const [add_name, set_add_name] = useState("");
  const handle_add_name = (e) => {
    set_add_name(e.target.value);
  };
  const handle_add_icon = (index) => {
    set_add_modal_open(true);
    set_add_index(index);
  };
  const [add_modal_open, set_add_modal_open] = useState(false);

  const handle_modal_Cancel = () => {
    set_add_modal_open(false);
    set_add_name("");
    set_add_index("");
  };
  const handle_submit_add_name = () => {
    if (add_index === "1") {
      add_breakdown_type(success_config, {
        breakdown_type: add_name,
      });
    }
    if (add_index === "2") {
      add_breakdown_nature(success_config, {
        breakdown_nature: add_name,
      });
    }
  };
  const success_config = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      if (add_index === "1") {
        get_breakdown_type(success_get_breakdown_type);
      }
      if (add_index === "2") {
        get_breakdown_nature(success_get_breakdown_nature);
      }
      handle_modal_Cancel();
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  const action_data = [
    {
      title: "Breakdown Type",
      action: !show_table ? (
        <Stack direction="row">
          <Select
            labelId="breakdown_type"
            onChange={handle_breakdown_type}
            value={breakdown_type}
            required
            fullWidth
            size="small"
            MenuProps={{ PaperProps: { sx: { maxHeight: 420 } } }}
          >
            {breakdown_type_list?.map((item) => {
              return (
                <MenuItem value={item?.breakdown_type_id}>
                  {item?.breakdown_type}
                </MenuItem>
              );
            })}
          </Select>
          <Tooltip title="Add Breakdown Type">
            <Button
              onClick={() => {
                handle_add_icon("1");
              }}
            >
              <AddIcon
                style={{
                  color: "#00C853",
                  fontSize: "32px",
                }}
              />
            </Button>
          </Tooltip>
        </Stack>
      ) : (
        breakdown_details?.breakdown_type
      ),
    },
    {
      title: "Breakdown Nature",
      action: !show_table ? (
        <Stack direction="row">
          <Select
            labelId="breakdown_nature"
            onChange={handle_breakdown_nature}
            value={breakdown_nature}
            required
            size="small"
            fullWidth
            MenuProps={{ PaperProps: { sx: { maxHeight: 420 } } }}
          >
            {breakdown_nature_list?.map((item) => {
              return (
                <MenuItem value={item?.breakdown_nature_id}>
                  {item?.breakdown_nature}
                </MenuItem>
              );
            })}
          </Select>
          <Tooltip title="Add Breakdown Nature">
            <Button
              onClick={() => {
                handle_add_icon("2");
              }}
            >
              <AddIcon
                style={{
                  color: "#00C853",
                  fontSize: "32px",
                }}
              />
            </Button>
          </Tooltip>
        </Stack>
      ) : (
        breakdown_details?.breakdown_nature
      ),
    },
    {
      title: "Spare Use",
      action: !show_table ? (
        <React.Fragment>
          {spare_use?.map((item) => (
            <Chip
              sx={{ m: 0.5 }}
              label={`${
                spare_parts_list?.find(
                  (spare) => spare.spare_part_id === item?.spare_part_id
                )?.spare_part_name
              }
              - ${item?.quantity}`}
              variant="outlined"
              onDelete={() => {
                const temp = [...spare_use];
                const temp2 = temp.filter(
                  (obj) => obj.spare_part_id !== item?.spare_part_id
                );
                set_spare_use(temp2);
              }}
            />
          ))}

          <IconButton size="small" onClick={showModal}>
            <AddIcon color="primary" />
          </IconButton>
        </React.Fragment>
      ) : (
        spare_use_details?.map((item) => {
          return (
            <Chip
              sx={{ m: 0.5 }}
              label={`${item?.spare_part_name}
            - ${item?.quantity}`}
              variant="outlined"
            />
          );
        })
      ),
    },
    {
      title: "Tool Use",
      action: !show_table ? (
        <OutlinedInput
          id="tool_use"
          type="text"
          name="tool_use"
          placeholder="Enter tools used"
          fullWidth
          required
          value={tool_use}
          onChange={handle_tool_use}
        />
      ) : (
        breakdown_details?.tool_use
      ),
    },
    {
      title: "Root Cause",
      action: !show_table ? (
        <OutlinedInput
          id="root_cause"
          type="text"
          name="root_cause"
          placeholder="Enter root cause"
          fullWidth
          required
          value={root_cause}
          onChange={handle_root_cause}
        />
      ) : (
        breakdown_details?.root_cause
      ),
    },
    {
      title: "Action Taken - Temporary",
      action: !show_table ? (
        !ticket_details?.problem_name ? (
          <OutlinedInput
            id="action_taken_temp"
            type="text"
            name="action_taken_temp"
            placeholder="Enter action"
            fullWidth
            required
            value={action_taken_temp}
            onChange={handle_action_taken_temp}
          />
        ) : (
          <Select
            labelId="Solution"
            onChange={handle_action_taken_temp}
            value={action_taken_temp}
            required
            size="small"
            fullWidth
            MenuProps={{ PaperProps: { sx: { maxHeight: 420 } } }}
          >
            {problemsList
              ?.find((item) => item?.problem_id === ticket_details?.problem_id)
              ?.solution_name?.map((item) => {
                return (
                  <MenuItem value={item?.solution_name}>
                    {item?.solution_name}
                  </MenuItem>
                );
              })}
          </Select>
        )
      ) : (
        breakdown_details?.action_taken_temp
      ),
    },
    {
      title: "Action Taken - Permanent",
      action: !show_table ? (
        !ticket_details?.problem_name ? (
          <OutlinedInput
            id="action_taken_perm"
            type="text"
            name="action_taken_perm"
            placeholder="Enter action"
            fullWidth
            required
            value={action_taken_perm}
            onChange={handle_action_taken_perm}
          />
        ) : (
          <Select
            labelId="Solution"
            onChange={handle_action_taken_perm}
            value={action_taken_perm}
            required
            size="small"
            fullWidth
            MenuProps={{ PaperProps: { sx: { maxHeight: 420 } } }}
          >
            {problemsList
              ?.find((item) => item?.problem_id === ticket_details?.problem_id)
              ?.solution_name?.map((item) => {
                return (
                  <MenuItem value={item?.solution_name}>
                    {item?.solution_name}
                  </MenuItem>
                );
              })}
          </Select>
        )
      ) : (
        breakdown_details?.action_taken_perm
      ),
    },
    {
      title: "Horizontal Deployment",
      action: !show_table ? (
        <OutlinedInput
          id="horizontal_deployment"
          type="text"
          name="horizontal_deployment"
          placeholder="Enter horizontal deployment"
          fullWidth
          required
          value={horizontal_deployment}
          onChange={handle_horizontal_deployment}
        />
      ) : (
        breakdown_details?.horizontal_deployment
      ),
    },
  ];

  const action_column = [
    {
      dataIndex: "title",
      width: "40%",
      align: "center",
    },
    {
      dataIndex: "action",
      width: "60%",
    },
  ];

  const handleSubmit = () => {
    add_machine_breakdown_details(success_add_machine_breakdown_details, {
      breakdown_type_id: breakdown_type,
      breakdown_nature_id: breakdown_nature,
      machine_id: machine_id,
      ticket_id: ticket_id,
      spare_use: spare_use,
      tool_use: tool_use,
      root_cause: root_cause,
      action_taken_temp: action_taken_temp,
      action_taken_perm: action_taken_perm,
      horizontal_deployment: horizontal_deployment,
    });
  };

  const success_add_machine_breakdown_details = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      get_machine_breakdown_details(success_get_machine_breakdown_details, {
        ticket_id: ticket_id,
      });
      handlePause();
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  const resetFunction = () => {
    set_breakdown_type("");
    set_breakdown_nature("");
    set_spare_use([]);
    set_tool_use("");
    set_horizontal_deployment("");
    set_action_taken_perm("");
    set_action_taken_temp("");
    set_root_cause("");
  };

  const handlePause = () => {
    const time = dayjs.utc().add(5, "hour").add(30, "minute");
    save_machine_time_record(success_time_update, {
      ticket_id: ticket_id,
      machine_id: machine_id,
      end_time: time,
    });
  };
  const success_time_update = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      window.location.reload();
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  const [is_started, set_is_started] = useState(false);

  function filterSpareUse(spare_parts_list, spare_use) {
    var usedSparePartIds = new Set(
      spare_use.map(function (use) {
        return use.spare_part_id;
      })
    );
    return spare_parts_list.filter(function (sparePart) {
      return !usedSparePartIds.has(sparePart.spare_part_id);
    });
  }
  const [open, setOpen] = useState(false);

  return (
    <Grid container>
      <Tooltip title="Support">
        <FloatButton
          icon={<QuestionCircleOutlined />}
          type="primary"
          style={{
            right: 24,
          }}
          onClick={() => setOpen(!open)}
        />
      </Tooltip>
      <Grid item xs={12} lg={9}>
        <MainCard title="Ticket Details" backbutton>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <Descriptions
                column={3}
                size="middle"
                contentStyle={{}}
                labelStyle={{
                  fontWeight: "800",
                }}
                bordered
                items={[
                  {
                    key: 2,
                    label: "Title",
                    children: ticket_details?.Summary,
                  },
                  {
                    key: 15,
                    label: "Problem",
                    children: ticket_details?.problem_name
                      ? ticket_details?.problem_name
                      : "Other",
                  },

                  {
                    key: 3,
                    label: "Ticket Id",
                    children: unique_id,
                  },
                  label_names["Asset Name"] && {
                    key: 1,
                    label: label_names["Asset Name"],
                    children: machine_details?.name,
                  },
                  label_names["Asset Number"] && {
                    key: 4,
                    label: label_names["Asset Number"],
                    children: machine_details?.assert_no,
                  },
                  {
                    key: 5,
                    label: "Installation Date",
                    children: machine_details?.installation_date?.slice(0, 19),
                  },
                  machine_details?.line_name && {
                    key: 6,
                    label: label_names["Line"],
                    children: machine_details?.line_name,
                  },
                  machine_details?.location && {
                    key: 7,
                    label: label_names["Location"],
                    children: machine_details?.location,
                  },
                  machine_details?.tester_name && {
                    key: 8,
                    label: label_names["Tester"],
                    children: machine_details?.tester_name,
                  },
                  machine_details?.model && {
                    key: 9,
                    label: label_names["Model"],
                    children: machine_details?.model,
                  },
                  machine_details?.machine_year && {
                    key: 10,
                    label: label_names["Asset Year"],
                    children: machine_details?.machine_year,
                  },
                  machine_details?.software_details && {
                    key: 11,
                    label: label_names["Software Details"],
                    children: machine_details?.software_details,
                  },
                  machine_details?.machine_type && {
                    key: 12,
                    label: label_names["Asset Type"],
                    children: machine_details?.machine_type,
                  },
                  machine_details?.frequency && {
                    key: 13,
                    label: "PM Frequency",
                    children: machine_details?.frequency,
                  },
                  machine_details?.due_date && {
                    key: 14,
                    label: "PM Date",
                    children: machine_details?.due_date?.slice(0, 19),
                  },
                  {
                    key: 3,
                    label: "Comment",
                    children: (
                      <Typography sx={{ color: "red" }}>
                        {ticket_details?.description}
                      </Typography>
                    ),
                  },
                ]?.filter(Boolean)}
              />
            </Grid>
            <Grid item lg={12}>
              <Stack direction="row" justifyContent="end">
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate("/report", {
                      state: {
                        machine_id: machine_id,
                      },
                    })
                  }
                >
                  Check history
                </Button>
              </Stack>
            </Grid>
          </Grid>

          {is_started &&
            instrutions_list?.some(
              (instruction) => instruction.instruction_name === "Completed"
            ) &&
            !show_table && (
              <Grid
                container
                sx={{
                  my: 5,
                }}
              >
                <Grid item lg={12}>
                  <Table
                    dataSource={action_data}
                    columns={action_column}
                    style={{ width: "100%" }}
                    bordered
                    size="small"
                    pagination={false}
                    showHeader={false}
                    title={() => {
                      return (
                        <Typography variant="h4" sx={{ mx: 2 }}>
                          Actions
                        </Typography>
                      );
                    }}
                  />
                </Grid>
                {instrutions_list?.some(
                  (instruction) => instruction.instruction_name === "Completed"
                ) && (
                  <Grid item lg={12}>
                    <Stack
                      direction="row"
                      justifyContent="end"
                      spacing={2}
                      sx={{ my: 2 }}
                    >
                      <Button
                        disabled={
                          breakdown_type === "" || breakdown_nature === ""
                        }
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          resetFunction();
                        }}
                      >
                        Reset
                      </Button>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            )}
          {show_table && (
            <Stack
              sx={{
                my: 5,
              }}
            >
              <Table
                dataSource={action_data}
                columns={action_column}
                style={{ width: "100%" }}
                bordered
                size="small"
                pagination={false}
                showHeader={false}
                title={() => {
                  return (
                    <Typography variant="h4" sx={{ mx: 2 }}>
                      Actions
                    </Typography>
                  );
                }}
              />
            </Stack>
          )}
          <Grid item lg={12}>
            <TimeLineData flow_data={ticket_flow} />
          </Grid>
        </MainCard>
      </Grid>
      <Grid container item xs={12} lg={3}>
        <Grid item lg={12}>
          <MainCard title="Time Elapsed">
            <Timer
              get_status={
                show_table === false &&
                instrutions_list?.some(
                  (instruction) => instruction.instruction_name === "Completed"
                )
              }
              set_is_started={set_is_started}
              ticket_id={ticket_id}
              machine_id={machine_id}
            />
          </MainCard>
          {instrutions_list?.length > 0 &&
            !instrutions_list?.some(
              (instruction) => instruction.instruction_name === "Completed"
            ) && (
              <MainCard>
                <ActionInTicket
                  transaction_id={transaction_id}
                  instrutions_list={instrutions_list}
                  machine_id={machine_details?.machine_id}
                />
              </MainCard>
            )}
          <MainCard>
            <CostInvolved history_ticket_id={ticket_id} />
          </MainCard>
        </Grid>
      </Grid>
      <Modal
        title="Spare Parts Used"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
        afterClose={() => {
          set_spare_part("");
          set_quantity("");
        }}
      >
        <form onSubmit={handle_spare_use}>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <Stack spacing={1}>
                <InputLabel required id="spare_part">
                  Spare Part
                </InputLabel>
                <Select
                  labelId="spare_part"
                  onChange={handle_spare_part}
                  value={spare_part}
                  required
                  MenuProps={{ PaperProps: { sx: { maxHeight: 420 } } }}
                >
                  {filterSpareUse(spare_parts_list, spare_use)?.map((item) => {
                    return (
                      <MenuItem value={item?.spare_part_id}>
                        {item?.spare_part_name} &nbsp; - &nbsp;
                        {item?.manufacturer_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Grid>
            <Grid item lg={12}>
              <Stack spacing={1}>
                <InputLabel required htmlFor="quantity">
                  Quantity
                </InputLabel>
                <OutlinedInput
                  id="quantity"
                  type="number"
                  name="quantity"
                  placeholder="Enter quantity"
                  fullWidth
                  required
                  value={quantity}
                  onChange={handle_quantity}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          "-webkit-appearance": "none",
                        },
                    },
                  }}
                />
                {error?.length > 0 && (
                  <FormHelperText error={error?.length > 0}>
                    {error}
                  </FormHelperText>
                )}
                {spare_part && (
                  <FormHelperText>
                    Available Inventory Spare Quantity:{" "}
                    {
                      spare_parts_list?.find(
                        (item) => item.spare_part_id === spare_part
                      )?.spare_quantity
                    }
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item lg={12} spacing={2}>
              <Stack spacing={2} direction="row" justifyContent="end">
                <Button variant="outlined" size="small" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="contained" size="small" type="submit">
                  Add
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Modal>
      <Modal
        title={`Add ${
          add_index === "1"
            ? "Breakdown Type"
            : add_index === "2" && "Breakdown Nature"
        }`}
        open={add_modal_open}
        okButtonProps={{ disabled: add_name.length === 0 }}
        onOk={handle_submit_add_name}
        onCancel={handle_modal_Cancel}
        okText="Add"
      >
        <Grid container spacing={1} direction="row" alignItems="center">
          <Grid item xs={12} lg={4}>
            <InputLabel id="name">
              {`${
                add_index === "1"
                  ? "Breakdown Type"
                  : add_index === "2" && "Breakdown Nature"
              } : `}
            </InputLabel>
          </Grid>
          <Grid item xs={12} lg={8}>
            <OutlinedInput
              id="name"
              type="text"
              name="text"
              size="small"
              placeholder={`Enter ${
                add_index === "1"
                  ? "Breakdown Type"
                  : add_index === "2" && "Breakdown Nature"
              }`}
              fullWidth
              required
              value={add_name}
              onChange={handle_add_name}
            />
          </Grid>
        </Grid>
      </Modal>
      <Drawer
        title={`Support`}
        placement="right"
        width={500}
        onClose={() => setOpen(false)}
        open={open}
        zIndex={2147}
      >
        <ChatBox
          params={{
            asset_type: machine_details?.machine_type,
            asset_model: machine_details?.model,
            asset_make: machine_details?.make,
            issue_type: ticket_details?.issue_type,
          }}
        />
      </Drawer>
    </Grid>
  );
}

export default BreakdownData;
