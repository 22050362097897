import { useEffect, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import {
  Radio,
  Tabs,
  Form,
  Select,
  Checkbox,
  Divider as AntDivider,
  Tooltip,
} from "antd";
import { get_api_function } from "utils/api";
// d, f, m,  t

const panel_id_list = {
  default: {
    Daily: [11, 16, 24, 25],
    Weekly: [54, 15, 53, 65],
    Monthly: [10, 14, 21, 23],
  },
  Locations: {
    Daily: [31, 28, 29, 30],
    Weekly: [9, 72, 55, 56],
    Monthly: [73, 88, 77, 85],
  },
  "Vehicle Types": {
    Daily: [37, 41, 44, 45],
    Weekly: [48, 62, 51, 63],
    Monthly: [74, 82, 78, 86],
  },
  Brand: {
    Daily: [35, 40, 43, 46],
    Weekly: [50, 60, 20, 66],
    Monthly: [75, 83, 79, 87],
  },
  Vehicles: {
    Daily: [36, 39, 42, 47],
    Weekly: [49, 61, 52, 64],
    Monthly: [76, 84, 80, 88],
  },
};

function VehicleAnalytics() {
  const timeoutRef = useRef(null);

  const [chart_filter, set_chart_filter] = useState("Daily");
  const [comp_filter, set_comp_filter] = useState();
  const [tab, set_tab] = useState("1");
  const [panel_ids, set_panel_ids] = useState(
    panel_id_list["default"]["Daily"]
  );

  const [locations_blur, set_locations_blur] = useState(["All"]);
  const [vehicle_type_blur, set_vehicle_type_blur] = useState(["All"]);
  const [vehicle_brand_blur, set_vehicle_brand_blur] = useState(["All"]);
  const [vehicle_blur, set_vehicle_blur] = useState(["All"]);

  const [locations, set_locations] = useState([]);
  const [vehicle_type, set_vehicle_type] = useState([]);
  const [vehicle_brand, set_vehicle_brand] = useState([]);
  const [vehicle, set_vehicle] = useState([]);

  const [locations_all, set_locations_all] = useState(true);
  const [vehicle_type_all, set_vehicle_type_all] = useState(true);
  const [vehicle_brand_all, set_vehicle_brand_all] = useState(true);
  const [vehicle_all, set_vehicle_all] = useState(true);

  const get_location_string = () => {
    return locations
      ?.map((item) => `var-Location=${item?.replace(/ /g, "+")}`)
      .join("&");
  };

  const get_vehicle_type_string = () => {
    return vehicle_type
      ?.map((item) => `var-Vehicle_Types=${item?.replace(/ /g, "+")}`)
      .join("&");
  };

  const get_vehicle_brand_string = () => {
    return vehicle_brand
      ?.map((item) => `var-Brand=${item?.replace(/ /g, "+")}`)
      .join("&");
  };

  const get_vehicle_string = () => {
    return vehicle
      ?.map((item) => `var-Vehicle=${item?.replace(/ /g, "+")}`)
      .join("&");
  };

  const filters2 = (
    <Stack direction="row" justifyContent="end" sx={{ width: "100%" }}>
      <Radio.Group
        onChange={({ target: { value } }) => {
          if (!comp_filter) {
            set_chart_filter(value);
            set_panel_ids(panel_id_list["default"][value]);
          } else {
            set_chart_filter(value);
            set_panel_ids(panel_id_list[comp_filter][value]);
          }
        }}
        value={chart_filter}
        buttonStyle="solid"
      >
        <Radio.Button value={"Daily"}>Daily</Radio.Button>
        <Radio.Button value={"Weekly"}>Weekly</Radio.Button>
        <Radio.Button value={"Monthly"}>Monthly</Radio.Button>
      </Radio.Group>
    </Stack>
  );

  const filters = (
    <Stack direction="row" spacing={2}>
      <Form.Item name="filter" label="Filter"></Form.Item>
      <Select
        style={{ minWidth: 120, maxWidth: 300 }}
        value={comp_filter}
        onChange={(value) => {
          set_comp_filter(value);
          set_panel_ids(panel_id_list[value][chart_filter]);
        }}
        placeholder="Select Filter"
        options={["Locations", "Vehicle Types", "Brand", "Vehicles"]?.map(
          (item) => ({
            lable: item,
            value: item,
          })
        )}
      />

      {/* {comp_filter === "Locations" && (
      )}
      {comp_filter === "Locations" && ( */}
      <Form.Item name="locations" label="Locations"></Form.Item>
      <Select
        mode="multiple"
        allowClear
        style={{ minWidth: 120, maxWidth: 300 }}
        value={locations_blur}
        onChange={(value) => {
          if (
            value?.filter((item) => item !== "All")?.length ===
              ["warehouse", "production", "dispatch"]?.length ||
            value?.length === 0
          ) {
            set_locations_all(true);
            set_locations_blur(["All"]);
          } else {
            set_locations_all(false);
            set_locations_blur(value?.filter((item) => item !== "All"));
          }
        }}
        onDropdownVisibleChange={(value) => {
          !value && set_locations(locations_blur);
        }}
        onDeselect={(value) => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(() => {
            set_locations(locations_blur?.filter((item) => item !== value));
          }, 1000);
        }}
        onClear={() => {
          set_locations_all(true);
          set_locations_blur(["All"]);
        }}
        dropdownRender={(menu) => (
          <>
            <Checkbox
              checked={locations_all}
              onChange={(e) => {
                set_locations_all(e.target.checked);
                e.target.checked
                  ? set_locations_blur(["All"])
                  : set_locations_blur([]);
              }}
              style={{ margin: "5px" }}
            >
              Select All
            </Checkbox>
            <AntDivider
              style={{
                margin: "1px",
              }}
            />
            {menu}
          </>
        )}
        options={["warehouse", "production", "dispatch"]?.map((item) => ({
          lable: item,
          value: item,
        }))}
      />
      {/* )} */}
      {/* {comp_filter === "Vehicle Types" && (
      )}
      {comp_filter === "Vehicle Types" && ( */}
      <Form.Item name="vehicle_types" label="Vehicle Types"></Form.Item>
      <Select
        mode="multiple"
        style={{ minWidth: 120, maxWidth: 300 }}
        value={vehicle_type_blur}
        onChange={(value) => {
          if (
            value?.filter((item) => item !== "All")?.length ===
              ["Buses", "Truck "]?.length ||
            value?.length === 0
          ) {
            set_vehicle_type_all(true);
            set_vehicle_type_blur(["All"]);
          } else {
            set_vehicle_type_all(false);
            set_vehicle_type_blur(value?.filter((item) => item !== "All"));
          }
        }}
        onDropdownVisibleChange={(value) => {
          !value && set_vehicle_type(vehicle_type_blur);
        }}
        onDeselect={(value) => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(() => {
            set_vehicle_type(
              vehicle_type_blur?.filter((item) => item !== value)
            );
          }, 1000);
        }}
        onClear={() => {
          set_vehicle_type_all(true);
          set_vehicle_type_blur(["All"]);
        }}
        dropdownRender={(menu) => (
          <>
            <Checkbox
              checked={vehicle_type_all}
              onChange={(e) => {
                set_vehicle_type_all(e.target.checked);
                e.target.checked
                  ? set_vehicle_type_blur(["All"])
                  : set_vehicle_type_blur([]);
              }}
              style={{ margin: "5px" }}
            >
              Select All
            </Checkbox>
            <AntDivider
              style={{
                margin: "1px",
              }}
            />
            {menu}
          </>
        )}
        options={["Buses", "Truck "]?.map((item) => ({
          lable: item,
          value: item,
        }))}
      />
      {/* )} */}
      {/* {comp_filter === "Brand" && (
      )}
      {comp_filter === "Brand" && ( */}
      <Form.Item name="brand" label="Brand"></Form.Item>
      <Select
        mode="multiple"
        style={{ minWidth: 120, maxWidth: 300 }}
        value={vehicle_brand_blur}
        onChange={(value) => {
          if (
            value?.filter((item) => item !== "All")?.length ===
              ["STARBUS", "TATA Motors"]?.length ||
            value?.length === 0
          ) {
            set_vehicle_brand_all(true);
            set_vehicle_brand_blur(["All"]);
          } else {
            set_vehicle_brand_all(false);
            set_vehicle_brand_blur(value?.filter((item) => item !== "All"));
          }
        }}
        onDropdownVisibleChange={(value) => {
          !value && set_vehicle_brand(vehicle_brand_blur);
        }}
        onDeselect={(value) => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(() => {
            set_vehicle_brand(
              vehicle_brand_blur?.filter((item) => item !== value)
            );
          }, 1000);
        }}
        onClear={() => {
          set_vehicle_brand_all(true);
          set_vehicle_brand_blur(["All"]);
        }}
        dropdownRender={(menu) => (
          <>
            <Checkbox
              checked={vehicle_brand_all}
              onChange={(e) => {
                set_vehicle_brand_all(e.target.checked);
                e.target.checked
                  ? set_vehicle_brand_blur(["All"])
                  : set_vehicle_brand_blur([]);
              }}
              style={{ margin: "5px" }}
            >
              Select All
            </Checkbox>
            <AntDivider
              style={{
                margin: "1px",
              }}
            />
            {menu}
          </>
        )}
        options={["STARBUS", "TATA Motors"]?.map((item) => ({
          lable: item,
          value: item,
        }))}
      />
      {/* )} */}
      {/* {comp_filter === "Vehicles" && (
      )}
      {comp_filter === "Vehicles" && ( */}
      <Form.Item name="vehicle" label="Vehicle"></Form.Item>
      <Select
        mode="multiple"
        maxTagCount="responsive"
        maxTagPlaceholder={(omittedValues) => (
          <Tooltip title={omittedValues.map(({ label }) => label).join(", ")}>
            +{omittedValues?.length}
          </Tooltip>
        )}
        value={vehicle_blur}
        style={{ minWidth: 300, maxWidth: 1000 }}
        onChange={(value) => {
          if (
            value?.filter((item) => item !== "All")?.length ===
              [
                "1816 LPT DCR49HSD-1",
                "SIGNA 2830.K 6.7L 38WB",
                "1816 LPT DCR49HSD-2",
                "SIGNA 4225 68 CAB 6.7L",
                "LPT 3525 56 COWL 6.7L",
                "TATA SIGNA 3718",
                "STARBUS PRIME 20 Seater-2",
                "STARBUS PRIME 20 Seater-3",
                "STARBUS PRIME 40 Seater-3",
                "STARBUS PRIME 20 Seater-1",
                "STARBUS PRIME 40 Seater-1",
                "STARBUS PRIME 40 Seater-2",
              ]?.length ||
            value?.length === 0
          ) {
            set_vehicle_all(true);
            set_vehicle_blur(["All"]);
          } else {
            set_vehicle_all(false);
            set_vehicle_blur(value?.filter((item) => item !== "All"));
          }
        }}
        onDropdownVisibleChange={(value) => {
          !value && set_vehicle(vehicle_blur);
        }}
        onDeselect={(value) => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(() => {
            set_vehicle(vehicle_blur?.filter((item) => item !== value));
          }, 1000);
        }}
        onClear={() => {
          set_vehicle_all(true);
          set_vehicle_blur(["All"]);
        }}
        dropdownStyle={{
          maxHeight: 400,
          minWidth: 300,
          overflow: "auto",
        }}
        dropdownRender={(menu) => (
          <>
            <Checkbox
              checked={vehicle_all}
              onChange={(e) => {
                set_vehicle_all(e.target.checked);
                e.target.checked
                  ? set_vehicle_blur(["All"])
                  : set_vehicle_blur([]);
              }}
              style={{ margin: "5px" }}
            >
              Select All
            </Checkbox>
            <AntDivider
              style={{
                margin: "1px",
              }}
            />
            {menu}
          </>
        )}
        options={[
          "1816 LPT DCR49HSD-1",
          "SIGNA 2830.K 6.7L 38WB",
          "1816 LPT DCR49HSD-2",
          "SIGNA 4225 68 CAB 6.7L",
          "LPT 3525 56 COWL 6.7L",
          "TATA SIGNA 3718",
          "STARBUS PRIME 20 Seater-2",
          "STARBUS PRIME 20 Seater-3",
          "STARBUS PRIME 40 Seater-3",
          "STARBUS PRIME 20 Seater-1",
          "STARBUS PRIME 40 Seater-1",
          "STARBUS PRIME 40 Seater-2",
        ]?.map((item) => ({
          lable: item,
          value: item,
        }))}
      />
      {/* )} */}
    </Stack>
  );

  const [token, set_token] = useState("");

  useEffect(() => {
    get_api_function("/graphana_access_url", (res) => {
      set_token(res.data.token);
    });
  }, []);

  return (
    <>
      <Tabs
        activeKey={tab}
        onChange={(key) => {
          set_tab(key);
        }}
        tabBarExtraContent={tab === "2" ? filters2 : ""}
        items={[
          {
            key: "1",
            label: "Overall",
            children: token && (
              <Grid container spacing={2}>
                <Grid container item lg={12}>
                  <iframe
                    src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&theme=light&panelId=1`}
                    width="100%"
                    height="350px"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
                <Grid container item lg={12} spacing={2}>
                  <Grid item lg={3}>
                    <iframe
                      src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&theme=light&panelId=2`}
                      width="100%"
                      height="100%"
                      frameborder="0"
                      referrerPolicy="origin"
                      title="1"
                    ></iframe>
                  </Grid>
                  <Grid container item spacing={2} lg={3}>
                    <Grid item lg={6}>
                      <iframe
                        src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&theme=light&panelId=4`}
                        width="100%"
                        height="100%"
                        frameborder="0"
                        referrerPolicy="origin"
                        title="1"
                      ></iframe>
                    </Grid>
                    <Grid item lg={6}>
                      <iframe
                        src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&theme=light&panelId=5`}
                        width="100%"
                        height="100%"
                        frameborder="0"
                        referrerPolicy="origin"
                        title="1"
                      ></iframe>
                    </Grid>
                    <Grid item lg={6}>
                      <iframe
                        src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&theme=light&panelId=3`}
                        width="100%"
                        height="100%"
                        frameborder="0"
                        referrerPolicy="origin"
                        title="1"
                      ></iframe>
                    </Grid>
                    <Grid item lg={6}>
                      <iframe
                        src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&theme=light&panelId=6`}
                        width="100%"
                        height="100%"
                        frameborder="0"
                        referrerPolicy="origin"
                        title="1"
                      ></iframe>
                    </Grid>
                  </Grid>
                  <Grid item lg={3}>
                    <iframe
                      src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&theme=light&panelId=7`}
                      width="100%"
                      height="100%"
                      frameborder="0"
                      referrerPolicy="origin"
                      title="1"
                    ></iframe>
                  </Grid>
                  <Grid item lg={3}>
                    <iframe
                      src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&theme=light&panelId=8`}
                      width="100%"
                      height="100%"
                      frameborder="0"
                      referrerPolicy="origin"
                      title="1"
                    ></iframe>
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
          {
            key: "2",
            label: "Comparison",
            children: token && (
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  {filters}
                </Grid>
                <Grid item lg={6} key="Distance Covered">
                  <iframe
                    src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&${get_location_string()}&${get_vehicle_type_string()}&${get_vehicle_brand_string()}&${get_vehicle_string()}&theme=light&panelId=${
                      panel_ids[0]
                    }`}
                    width="100%"
                    height="400"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
                <Grid item lg={6} key="Fuel Consumption">
                  <iframe
                    src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&${get_location_string()}&${get_vehicle_type_string()}&${get_vehicle_brand_string()}&${get_vehicle_string()}&theme=light&panelId=${
                      panel_ids[1]
                    }`}
                    width="100%"
                    height="400"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
                <Grid item lg={6} key="Mileage">
                  <iframe
                    src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&${get_location_string()}&${get_vehicle_type_string()}&${get_vehicle_brand_string()}&${get_vehicle_string()}&theme=light&panelId=${
                      panel_ids[2]
                    }`}
                    width="100%"
                    height="400"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
                <Grid item lg={6} key="No of Trips">
                  <iframe
                    src={`https://monitoring.nexgensis.com/d-solo/b946b68a-35af-4585-b788-7d9ebf4474ed/vehicles?orgId=1&auth=${token}&${get_location_string()}&${get_vehicle_type_string()}&${get_vehicle_brand_string()}&${get_vehicle_string()}&theme=light&panelId=${
                      panel_ids[3]
                    }`}
                    width="100%"
                    height="400"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </>
  );
}

export default VehicleAnalytics;
