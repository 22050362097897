import React from "react";
import {
  Upload,
  Button as Button1,
  Typography as Typography1,
  Modal,
  Table,
} from "antd";
import {
  Typography,
  Stack,
  Button,
  MenuItem,
  OutlinedInput,
  Grid,
  Select,
  InputLabel,
  Drawer,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { Timeline, Image, Descriptions } from "antd";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function TimeLineData({ flow_data }) {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Timeline
      style={{
        margin: "50px 0",
        width: matchDownSM ? "100%" : "900px",
      }}
      mode="left"
      items={[
        ...flow_data?.map((flow, index) => {
          return {
            label:
              flow.Status !== "Resolved" &&
              flow.Status !== "Issue Not Resolved" &&
              (matchDownSM
                ? null
                : flow?.date?.slice(0, 19) || flow?.Date?.slice(0, 19)),
            color:
              index === 0
                ? "green"
                : flow?.TimeDifferenceComparison
                ? "green"
                : "red",
            children: (
              <React.Fragment>
                <Typography1.Title
                  level={5}
                  ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                >
                  {flow.Status}
                </Typography1.Title>
                <Descriptions column={1}>
                  {flow.Status !== "Resolved" &&
                    flow.Status !== "Issue Not Resolved" && (
                      <React.Fragment>
                        <Descriptions.Item
                          style={{
                            display: flow?.By ? "block" : "none",
                          }}
                          label="Name"
                        >
                          {flow.By}
                        </Descriptions.Item>
                        {matchDownSM && (
                          <Descriptions.Item label="Date">
                            {flow?.date?.slice(0, 19) ||
                              flow?.Date?.slice(0, 19)}
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item
                          style={{
                            display: flow?.Department ? "block" : "none",
                          }}
                          label="Department"
                        >
                          {flow?.Department}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label="Designation"
                          style={{
                            display: flow?.Designation ? "block" : "none",
                          }}
                        >
                          {flow.Designation}
                        </Descriptions.Item>
                        <Descriptions.Item
                          style={{
                            display: flow?.comment ? "block" : "none",
                          }}
                          label="Comment"
                        >
                          {flow.comment}
                        </Descriptions.Item>
                        {flow?.TimeDifference &&
                          flow?.TimeDifferenceComparison !== null && (
                            <Descriptions.Item label="Time Taken">
                              <Typography
                                sx={{
                                  color: flow?.TimeDifferenceComparison
                                    ? "#52C41A"
                                    : "#FF4D4F",
                                }}
                              >
                                {flow?.TimeDifference}
                              </Typography>
                            </Descriptions.Item>
                          )}
                        {(flow.attachment || flow?.image_data) && (
                          <Descriptions.Item label="Attachments">
                            <Stack direction="row" spacing={2}>
                              {(flow.attachment || flow?.image_data) && (
                                <Image
                                  width={100}
                                  src={`data:image/png;base64,${
                                    flow?.attachment || flow?.image_data
                                  }`}
                                />
                              )}
                              {(flow.attachment1 || flow?.image_data1) && (
                                <Image
                                  width={100}
                                  src={`data:image/png;base64,${
                                    flow?.attachment1 || flow?.image_data1
                                  }`}
                                />
                              )}
                              {(flow.attachment2 || flow?.image_data2) && (
                                <Image
                                  width={100}
                                  src={`data:image/png;base64,${
                                    flow?.attachment2 || flow?.image_data2
                                  }`}
                                />
                              )}
                            </Stack>
                          </Descriptions.Item>
                        )}
                      </React.Fragment>
                    )}
                </Descriptions>
              </React.Fragment>
            ),
          };
        }),
      ]}
    />
  );
}

export default TimeLineData;
