import React, { useEffect, useState } from "react";
import { Table } from "antd";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  get_api_function,
  post_api_function,
  update_maintenace_ticket_status,
} from "utils/api";
import { toast } from "react-toastify";
import dayjs from "dayjs";

function InsuranceTicketTable({ current_menu }) {
  const [insurance_data, set_insurance_data] = useState([]);
  const [refresh, set_refresh] = useState(1)
  const get_function = () => {
    get_api_function(
      "/get_reneval_info_for_ticket",
      (res) => {
        res.data.status === "success" && set_insurance_data(res.data.data);
      },
      {
        list_type: current_menu,
      }
    );
  };

  const insurance_columns = [
    {
      title: "Vehicle",
      dataIndex: "machine",
      key: "machine",
      align: "center",
    },
    {
      title: "Vehicle Number",
      dataIndex: "assert_no",
      key: "assert_no",
      align: "center",
    },
    current_menu !== "completed" &&   {
      title: "Insurer",
      dataIndex: "insurer",
      key: "insurer",
      align: "center",
    },
    current_menu !== "completed" &&   {
      title: "Insurance Policy Number",
      dataIndex: "policyNumber",
      key: "policyNumber",
      align: "center",
    },
    current_menu !== "completed" &&   {
      title: "Current Policy Expiry Date",
      dataIndex: "policyExpiryDate",
      key: "policyExpiryDate",
      render: (policyExpiryDate) => policyExpiryDate?.slice(0, 19),
      align: "center",
    },
    {
      title: "Updated Insurer",
      key: "insurer",
      dataIndex: "updated_insurer",
      render: (_, record, index) =>
        record?.isUpdated ? (
          record?.updated_insurer
        ) : (
          <OutlinedInput
            id="updated_insurer"
            type="text"
            value={record?.updated_insurer || ""}
            name="updated_insurer"
            onChange={(e) => {
              set_insurance_data((insurance_data) => {
                const temp = [...insurance_data];
                temp[index] = {
                  ...record,
                  updated_insurer: e.target.value,
                };
                return temp;
              });
            }}
            fullWidth
          />
        ),
      align: "center",
    },
    {
      title: "Updated Policy Number",
      key: "updated_policy_number",
      dataIndex: "updated_policy_number",
      render: (updated_policy_number, record, index) =>
        record?.isUpdated ? (
          record?.updated_policy_number
        ) : (
          <OutlinedInput
            id="updated_policy_number"
            type="text"
            value={record?.updated_policy_number || ""}
            name="updated_policy_number"
            onChange={(e) => {
              set_insurance_data((insurance_data) => {
                const temp = [...insurance_data];
                temp[index] = {
                  ...record,
                  updated_policy_number: e.target.value,
                };
                return temp;
              });
            }}
            fullWidth
          />
        ),
      align: "center",
    },
    {
      title: "Insurance Policy Cost",
      dataIndex: "updated_policy_cost",
      key: "updated_policy_cost",
      render: (updated_policy_cost, record, index) =>
        record?.isUpdated ? (
          record?.updated_policy_cost
        ) : (
          <OutlinedInput
            id="updated_policy_cost"
            type="number"
            value={record?.updated_policy_cost || ""}
            name="updated_policy_cost"
            onChange={(e) => {
              set_insurance_data((insurance_data) => {
                const temp = [...insurance_data];
                temp[index] = {
                  ...record,
                  updated_policy_cost: e.target.value,
                };
                return temp;
              });
            }}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-input": {
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                },
              },
            }}
          />
        ),
      align: "center",
    },
    {
      title: "Updated Policy Expiry Date",
      key: "updatePolicyExpiryDate",
      render: (updated_policy_expiry_date, record, index) =>
        record?.isUpdated ? (
          record?.updated_policy_expiry_date
        ) : (
          <OutlinedInput
            id="updated_policy_expiry_date"
            type="date"
            name="updated_policy_expiry_date"
            value={record?.updated_policy_expiry_date}
            onChange={(e) => {
              set_insurance_data((insurance_data) => {
                const temp = [...insurance_data];
                temp[index] = {
                  ...record,
                  updated_policy_expiry_date: e.target.value,
                };
                return temp;
              });
            }}
            fullWidth
          />
        ),
      align: "center",
    },
    current_menu !== "completed" && {
      title: "Action",
      key: "policyExpiryDate",
      render: (maintenance_date, record, index) => (
        <Stack direction="row" justifyContent="center" spacing={1}>
          {record?.isUpdated ? (
            <Typography
              sx={{
                color: "#52C41A",
              }}
            >
              Updated
            </Typography>
          ) : (
            <Tooltip title="Update">
              <Button
                size="small"
                variant="contained"
                disabled={
                  !record?.updated_policy_number ||
                  !record?.updated_policy_cost ||
                  record?.updated_policy_expiry_date === "None" ||
                  !record?.updated_policy_expiry_date
                }
                color="success"
                onClick={() => {
                  post_api_function(
                    "/update_reneval_info_for_ticket",
                    (res) => {
                      if (res.status === "success") {
                        toast.success(res.message);
                        set_refresh(!refresh)
                        // get_api_function(
                        //   "/get_reneval_info_for_ticket",
                        //   (res) => {
                        //     if (res.data.status === "success") {
                        //       // const temp_insu_data = res.data.data;
                        //       // const temp = [...insurance_data];
                        //       // temp[index] = {
                        //       //   ...temp_insu_data[index],
                        //       // };
                        //       set_insurance_data(res.data.data);
                        //     }
                        //   },
                        //   {
                        //     list_type: current_menu,
                        //   }
                        // );
                      } else if (res.status === "failed") {
                        toast.warning(res.message);
                      }
                    },
                    record
                  );
                }}
              >
                Update
              </Button>
            </Tooltip>
          )}
        </Stack>
      ),
      align: "center",
    },
  ]?.filter(Boolean);

  useEffect(() => {
    get_function();
  }, [current_menu, refresh]);

  return (
    <Stack spacing={2}>
      <Table
        dataSource={insurance_data}
        columns={insurance_columns}
        scroll={{ x: 100, y: 490 }}
        bordered
        size="small"
        pagination={false}
      />
      {/* {show_submit && (
        <Stack direction="row" justifyContent="end" sx={{ my: 2 }}>
          <Button
            variant="contained"
            disabled={insurance_data?.some((item) => !item?.isUpdated)}
            onClick={() => {
              update_maintenace_ticket_status(
                (res) => {
                  if (res.status === "success") {
                    toast.success(res.message);
                    window.location.reload();
                  } else if (res.status === "failed") {
                    toast.warning(res.message);
                  }
                },
                {
                  ticket_id: ticket_id,
                }
              );
            }}
          >
            Submit
          </Button>
        </Stack>
      )} */}
    </Stack>
  );
}

export default InsuranceTicketTable;
