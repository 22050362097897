import { useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import {
  InputLabel,
  Stack,
  Button,
  OutlinedInput,
  Tooltip,
  MenuItem,
  Grid,
} from "@mui/material";
import {
  get_employee_by_role,
  get_instruction_status,
  get_roles,
  get_roles_config,
} from "utils/api";
import { Card, Select, Form } from "antd";
import { useSelector, useDispatch } from "react-redux";
import workflowData, {
  set_workflowData,
  set_workflowData_list,
  set_workflowData_on_node,
} from "store/reducers/workflowData";
import { set_workflowActionList } from "store/reducers/workflowActionList";

function TextUpdaterNode({ data, isConnectable }) {
  const data_list = useSelector((state) => state?.workflowData);
  let Datalist = JSON.parse(JSON.stringify(data_list));
  const source_id = Datalist?.find((item) => item?.target === data?.id)?.source;
  const target_ids = Datalist?.filter(
    (item) => item?.source === source_id
  )?.map((item) => item?.target);

  const workflow_action_list = useSelector(
    (state) => state?.workflowActionList
  );
  const dispatch = useDispatch();

  const filter_instruction_list = (value, current_id) => {
    let group_value = instrutions_list.filter((item) => {
      return item.instruction_id === value;
    })[0]?.group[0];

    const selected_action = data_list
      ?.filter((item) => target_ids?.includes(item?.id))
      ?.map((selected_action) => selected_action?.instruction_id);

    dispatch(
      set_workflowActionList(
        instrutions_list?.map((item) => {
          const disable_value = selected_action?.includes(item?.instruction_id);
          return {
            ...item,
            group_status: item.group.includes(group_value),
            disabled:
              item?.instruction_id === current_id ? false : disable_value,
          };
        })
      )
    );
  };

  const instruction_handle = (value) => {
    dispatch(
      set_workflowData({
        id: data?.id,
        key: "instruction_id",
        value: value,
      })
    );
    ["Completed", "Approve", "Approve & Assign", "Verified"]?.includes(
      instrutions_list?.find((item) => item?.instruction_id === value)
        ?.instruction_name
    ) && filter_instruction_list(value, value);
  };

  useEffect(() => {
    target_ids?.length === 1 &&
      dispatch(set_workflowActionList(instrutions_list));
  }, []);

  const role_handle = (value) => {
    for (let i = 0; i < Datalist.length; i++) {
      if (target_ids.includes(Datalist[i]?.id)) {
        Datalist[i].role_id = value;
      }
    }
    dispatch(set_workflowData_list(Datalist));
  };

  const employee_handle = (value) => {
    for (let i = 0; i < Datalist.length; i++) {
      if (target_ids.includes(Datalist[i]?.id)) {
        Datalist[i].employee_id = value;
      }
    }
    dispatch(set_workflowData_list(Datalist));
  };

  const [instrutions_list, set_instructions_list] = useState([]);
  const [role_list, set_role_list] = useState([]);
  const [employee_list, set_employee_list] = useState([]);
  const [initiate, set_initiate] = useState(3);

  const success_set_instructions_list = async (res) => {
    await set_instructions_list(res.data.data);
    // await dispatch(set_workflowActionList(res.data.data));
    set_initiate(
      res?.data?.data?.find(
        (instruction) => instruction.instruction_name === "Initiate"
      ).instruction_id
    );
    await get_roles_config(success_set_role_list, {
      instruction_id: res?.data?.data?.find(
        (instruction) => instruction.instruction_name === "Initiate"
      ).instruction_id,
    });
  };

  const success_set_role_list = async (res) => {
    await set_role_list(res.data.data);
    !data_list.some((obj) => obj.id === "0") &&
      dispatch(
        set_workflowData_on_node({
          id: "0",
          instruction_id: initiate,
          role_id: res.data.data?.find((item) => item?.role === "All")?.role_id,
          employee_id: "",
        })
      );
  };

  const success_set_employee_list = (res) => {
    set_employee_list(res.data.data);
  };

  useEffect(() => {
    get_instruction_status(success_set_instructions_list);
  }, []);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
    spacing: 0,
  };

  return (
    <Card
      size="small"
      style={{ width: 250, border: "1px solid #000", borderRadius: "5px" }}
    >
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />

      <Form {...layout}>
        <Form.Item
          label="Action"
          style={{ margin: "0px", marginBottom: "5px" }}
        >
          <Select
            className="nodrag nopan"
            onChange={instruction_handle}
            value={data_list?.length > 0 && data_list[data?.id]?.instruction_id}
            allowClear
            options={
              workflow_action_list.length > 0
                ? workflow_action_list?.map((item) => {
                    return {
                      disabled: item?.disabled || !item.group_status,
                      value: item.instruction_id,
                      label: item.instruction_name,
                    };
                  })
                : instrutions_list?.map((item) => {
                    return {
                      disabled: item?.priority == 1 ? false : true,
                      value: item.instruction_id,
                      label: item.instruction_name,
                    };
                  })
            }
            onDropdownVisibleChange={(open) => {
              if (open) {
                const current_id = data_list[data?.id]?.instruction_id;
                if (
                  [
                    "Completed",
                    "Approve",
                    "Approve & Assign",
                    "Verified",
                  ]?.includes(
                    instrutions_list?.find(
                      (item) => item?.instruction_id === current_id
                    )?.instruction_name
                  )
                ) {
                  filter_instruction_list(current_id, current_id);
                } else {
                  const targets = Datalist?.filter(
                    (item) => item?.source === source_id
                  )?.map((item) => item?.instruction_id);
                  const main_action_ids = instrutions_list
                    ?.filter((item) =>
                      [
                        "Completed",
                        "Approve",
                        "Approve & Assign",
                        "Verified",
                      ]?.includes(item?.instruction_name)
                    )
                    ?.map((item) => item?.instruction_id);

                  const filtered_value = targets?.filter((number) =>
                    main_action_ids?.includes(number)
                  );
                  filtered_value?.length === 1
                    ? filter_instruction_list(filtered_value[0], current_id)
                    : dispatch(set_workflowActionList([]));
                }
              }
            }}
            onBlur={() => {
              get_roles_config(success_set_role_list, {
                instruction_id:
                  data_list?.length > 0 && data_list[data?.id]?.instruction_id,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Role" style={{ margin: "0px", marginBottom: "5px" }}>
          <Select
            className="nodrag nopan"
            onChange={role_handle}
            value={data_list?.length > 0 && data_list[data?.id]?.role_id}
            allowClear
            options={role_list?.map((item) => {
              return {
                value: item?.role_id,
                label: item.role,
              };
            })}
            onBlur={() => {
              get_employee_by_role(success_set_employee_list, {
                role_id: data_list?.length > 0 && data_list[data?.id]?.role_id,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Employee"
          style={{ margin: "0px", marginBottom: "5px" }}
        >
          <Select
            className="nodrag nopan"
            onChange={employee_handle}
            allowClear
            value={data_list?.length > 0 && data_list[data?.id]?.employee_id}
            options={employee_list?.map((item) => {
              return {
                value: item?.employee_id,
                label: item?.employee_name,
              };
            })}
          />
        </Form.Item>
      </Form>

      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </Card>
  );
}

export default TextUpdaterNode;