import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainModuleLayout from "layout/MainModuleLayout";

// render - login
const MainModule = Loadable(
  lazy(() => import("pages/Preventive-maintenance/MainModule/MainModule"))
);
const UserProfile = Loadable(lazy(() => import("../components/UserProfile")));

const ModuleRoutes = () => {
  return {
    path: "/",
    element: <MainModuleLayout />,
    children: [
      {
        path: "/modules",
        element: <MainModule />,
      },
      {
        path: "/userprofile",
        element: <UserProfile />,
      },
    ],
  };
};

export default ModuleRoutes;
