import { createSlice } from "@reduxjs/toolkit";

const labelsSLice = createSlice({
  name: "ModuleLables",
  initialState: {
    Vehicles: {
      "Identification Number": "Vehicle Identification Number",
      "Select Source Asset": "Source Vehicle",
      "Asset Type": "Vehicle Type",
      "Asset Name": "Vehicle Name",
      "Asset Number": "Vehicle Number",
      Vendor: "Manufacturer",
      Model: "Model",
      "Asset Image": "Vehicle Image",
      "Maintenance Frequency": "Maintenance Frequency",
      "Asset Status": "Vehicle Status",
      Tester: "Inspector",
      Owner: "Driver",
      Line: "Fleet Line",
      Location: "Location",
      "Asset Year": "Year of Manufacture",
      "Asset Cost": "Vehicle Cost",
      "Asset Manual": "Vehicle Manual",
      "Maintenance Instruction Manual": "Maintenance Manual",
      Specification: "Specification",
      "PM Checklist Parameters": "Maintenance Checklist",
      "Spare Parts": "Spare Parts",
      Description: "Vehicle Description",
      "Software Details": "Insurance Company",
      "Software License": "Insurance Policy Number",
      "Software License Cost": "Insurance Policy Cost",
      "Software License Expiry Date": "Insurance Policy Expiry Date",
      "Useful Life": "Useful Life",
      "Fitness Expiry Date": "Fitness Expiry Date",
      "Fitness Certificate": "Fitness Certificate",
    },
    Buildings: {
      "Identification Number": "Property Identification Number",
      "Select Source Asset": "Source Facility",
      "Asset Type": "Facility Type",
      "Asset Name": "Facility Name",
      "Asset Number": "Facility ID",
      "Asset Image": "Facility Image",
      "Maintenance Frequency": "Maintenance Frequency",
      "Asset Status": "Facility Status",
      Tester: "Inspector",
      Owner: "Owner",
      Line: "Division",
      Location: "Location",
      "Asset Year": "Construction Year",
      "Asset Cost": "Construction Cost",
      Description: "Facility Address",
      "Asset Manual": "Facility Manual",
      "Maintenance Instruction Manual": "Maintenance Manual",
      Specification: "Facility Specification",
      "PM Checklist Parameters": "Maintenance Checklist",
      "Spare Parts": "Spare Parts",
    },
    Machines: {
      "Select Source Asset": "Select Source Machine",
      "Identification Number": "Registration Number",
      "Asset Type": "Machine Type",
      "Asset Name": "Machine Name",
      "Asset Number": "Machine Number",
      Vendor: "Vendor",
      Model: "Model",
      Manufacturer: "Manufacturer",
      "Asset Image": "Machine Image",
      "Maintenance Frequency": "Maintenance Frequency",
      "Asset Status": "Machine Status",
      Tester: "Tester",
      Owner: "Owner",
      Line: "Line",
      Location: "Location",
      "Asset Year": "Machine Year",
      "Asset Cost": "Machine Cost",
      "Software Details": "Software Details",
      "Software License": "Software License",
      "Software License Cost": "Software License Cost",
      "Software License Expiry Date": "Software License Expiry Date",
      Description: "Description",
      "S.O.P. Document": "S.O.P. Document",
      "Asset Manual": "Machine Manual",
      "Maintenance Instruction Manual": "Maintenance Instruction Manual",
      Specification: "Specification",
      "PM Checklist Parameters": "PM Checklist Parameters",
      "Spare Parts": "Spare Parts",
    },
    Other: {
      "Identification Number": "Identification Number",
      "Select Source Asset": "Select Source Asset",
      "Asset Type": "Asset Type",
      "Asset Name": "Asset Name",
      "Asset Number": "Asset Number",
      Vendor: "Vendor",
      Model: "Model",
      "Asset Image": "Asset Image",
      "Maintenance Frequency": "Maintenance Frequency",
      "Asset Status": "Asset Status",
      Tester: "Tester",
      Owner: "Owner",
      Line: "Line",
      Location: "Location",
      "Asset Year": "Asset Year",
      "Asset Cost": "Asset Cost",
      "Software Details": "Software Details",
      "Software License": "Software License",
      "Software License Cost": "Software License Cost",
      "Software License Expiry Date": "Software License Expiry Date",
      Description: "Description",
      "S.O.P. Document": "S.O.P. Document",
      "Asset Manual": "Asset Manual",
      "Maintenance Instruction Manual": "Maintenance Instruction Manual",
      Specification: "Specification",
      "PM Checklist Parameters": "PM Checklist Parameters",
      "Spare Parts": "Spare Parts",
    },
  },
  reducers: {
    set_ModuleLabels: (state, action) => {
      return action.payload;
    },
  },
});

export const { set_ModuleLabels } = labelsSLice.actions;
export default labelsSLice.reducer;
