// project import
import React from "react";

import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import { HashRouter } from "react-router-dom";
import "react-chat-elements/dist/main.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FadeLoader from "react-spinners/FadeLoader";
import loader from "store/reducers/loader";
import { setLoading } from "store/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
const spinnerContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  zIndex: 9999,
};

const App = () => {
  const load = useSelector((state) => state.loader);
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <ThemeCustomization>
          {load && (
            <div style={spinnerContainerStyle}>
              <FadeLoader
                size={100}
                color={"#ffff"}
                type="ball-spin-fade"
                loading={loader}
              />
            </div>
          )}
          <ScrollTop>
            <Routes />
          </ScrollTop>
        </ThemeCustomization>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
