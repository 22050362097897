import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  Controls,
  Background,
  MarkerType,
} from "reactflow";
import "reactflow/dist/style.css";
import "../../../assets/styles/flow.css";
import {
  InputLabel,
  Stack,
  Button,
  OutlinedInput,
  Tooltip,
  MenuItem,
  Grid,
  Select,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { Select as Select1 } from "antd";
import TextUpdaterNode from "./TextUpdaterNode";
import { useSelector, useDispatch } from "react-redux";
import {
  set_workflowData_list,
  set_workflowData_on_node,
} from "store/reducers/workflowData";
import { set_workflowNodes_list } from "store/reducers/workflowNodes";
import { set_workflowEdges_list } from "store/reducers/workflowEdges";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";

import MainCard from "components/MainCard";
import {
  add_issue_type,
  get_departments,
  issue_type_dept_config,
} from "utils/api";

const nodeTypes = { textUpdater: TextUpdaterNode };

const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

const initialNodes = [
  {
    id: "0",
    type: "textUpdater",
    data: { id: 0 },
    position: { x: 0, y: 50 },
  },
];

let id = 1;
const getId = () => `${id++}`;

const AddNodeOnEdgeDrop = () => {
  const original_data = useSelector((state) => state?.workflowData);
  const dispatch = useDispatch();

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { screenToFlowPosition } = useReactFlow();

  const onConnect = useCallback((params) => {
    if (connectingNodeId.current) return; //dont connect edge if the target is already exists
    // console.log(params);
    connectingNodeId.current = null;
    setEdges((eds) => addEdge(params, eds));
  }, []);

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;
      const targetIsPane = event.target.classList.contains("react-flow__pane");
      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = getId();
        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          data: {
            id: id,
          },
          origin: [0.5, 0.0],
          type: "textUpdater",
          dragHandle: false,
          selected: false,
        };

        dispatch(
          set_workflowData_on_node({
            id,
            source: connectingNodeId.current,
            target: id,
            instruction_id: "",
            role_id: "",
            employee_id: "",
          })
        );

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({
            id,
            source: connectingNodeId.current,
            target: id,
            type: "step",
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 40,
              height: 40,
              color: "#784be8",
            },
          })
        );
      }
    },
    [screenToFlowPosition]
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      let data_list = [...original_data];
      deleted.forEach((deletedItem) => {
        const index = data_list.findIndex((item) => {
          return item && parseInt(item.id) === parseInt(deletedItem.id);
        });
        if (index !== -1) {
          data_list[index] = null;
        }
      });

      dispatch(set_workflowData_list(data_list));
    },
    [nodes, edges]
  );

  useEffect(() => {
    dispatch(set_workflowNodes_list(nodes));
  }, [nodes]);

  useEffect(() => {
    dispatch(set_workflowEdges_list(edges));
  }, [edges]);

  const submit_function = (data) => {
    const sourceMap = {};

    data.forEach((item) => {
      if (item.source) {
        if (!sourceMap[item.source]) {
          sourceMap[item.source] = {
            ...item,
            instruction_id: item.instruction_id.toString(),
            ticket_type_id: 224,
            stage_id: 0,
          };
        } else {
          sourceMap[item.source].instruction_id += `,${item.instruction_id}`;
        }
      }
    });

    const final = Object.values(sourceMap).map((item, index) => ({
      ...item,
      stage_id: index + 2,
    }));

    return [
      {
        role_id: 8,
        instruction_id: 3,
        stage_id: 1,
        ticket_type_id: 223,
      },
      ...final,
    ];
  };

  return (
    <MainCard>
      <UperKaSection />
      <div
        className="wrapper"
        ref={reactFlowWrapper}
        style={{ height: "65vh" }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          onNodesDelete={onNodesDelete}
          fitView
          defaultViewport={defaultViewport}
          fitViewOptions={{ padding: 2 }}
          nodeOrigin={[0.5, 0]}
          nodeTypes={nodeTypes}
          style={{
            backgroundColor: "#E3F2FD",
          }}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
      <Stack direction="row" justifyContent="end" sx={{ m: 2 }}>
        <Button
          variant="contained"
          onClick={() => {
            console.log("Datalist ==>", original_data);
            console.log("Edges ==>", edges);
            console.log("Nodes ==>", nodes);
            console.log("work_flow ==>", submit_function(original_data));
          }}
        >
          Submit
        </Button>
      </Stack>
    </MainCard>
  );
};

const MainFlow = () => (
  <ReactFlowProvider>
    <AddNodeOnEdgeDrop />
  </ReactFlowProvider>
);
export default MainFlow;

export const UperKaSection = () => {
  const [department_list, set_department_list] = useState([]);
  const [work_flow, set_workflow] = useState("");
  const [work_flow_id, set_workflow_id] = useState("");
  const [department, set_department] = useState("");
  const [department_submit, set_department_submit] = useState(false);

  const success_set_department = (res) => {
    set_department_list(res.data.data);
  };

  const sucess_add_issue_type = (res) => {
    set_workflow_id(res?.issue_type_id);
    if (res.status === "success") {
      toast.success(res.message);
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };
  const success_issue_type_dept_config = (res) => {
    if (res.status === "success") {
      set_department_submit(true);
      toast.success(res.message);
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  useEffect(() => {
    get_departments(success_set_department);
  }, []);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        mb: 2,
      }}
    >
      <Grid item lg={4}>
        <Stack spacing={1}>
          <InputLabel htmlFor="issue-type">Issue Type</InputLabel>
          <Stack direction="row" spacing={1}>
            <OutlinedInput
              id="issue-type"
              type="text"
              name="make"
              placeholder="Enter Issue Type"
              fullWidth
              value={work_flow}
              onChange={(e) => set_workflow(e.target.value)}
              disabled={work_flow_id}
            />
            {!work_flow_id && (
              <Button
                variant="contained"
                onClick={() => {
                  add_issue_type(
                    JSON.stringify({
                      issue_type: work_flow,
                    }),
                    sucess_add_issue_type
                  );
                }}
              >
                Add
              </Button>
            )}
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Stack spacing={1}>
          <InputLabel required htmlFor="department">
            Department
          </InputLabel>
          <Stack direction="row" spacing={1}>
            <Select1
              showSearch
              id="line"
              allowClear
              size="large"
              disabled={department_submit}
              placeholder="Select Department"
              labelRender={(props) => {
                return props?.label?.props?.children;
              }}
              optionFilterProp="children"
              onChange={(value) => {
                set_department(value);
              }}
              value={department}
              filterOption={(input, option) =>
                (option?.label2 ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{
                width: "100%",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              bordered={false}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              options={department_list?.map((item) => {
                return {
                  value: item?.department_id,
                  label: (
                    <div
                      style={{
                        maxWidth: "100%",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {item?.department_name}
                    </div>
                  ),
                  label2: item?.department_name,
                };
              })}
            />
            {!department_submit && (
              <Button
                variant="contained"
                onClick={async () => {
                  await issue_type_dept_config(
                    {
                      issue_type_id: work_flow_id,
                      department_id: department,
                    },
                    success_issue_type_dept_config
                  );
                }}
              >
                Add
              </Button>
            )}
          </Stack>
          {false && <FormHelperText error>Line is required</FormHelperText>}
        </Stack>
      </Grid>
    </Grid>
  );
};
