import React, { useEffect, useState } from "react";
import { Stack, OutlinedInput, AppBar, Toolbar, Box } from "@mui/material";

import com from "../../assets/images/CompanyLogo1.jpg";
import Profile from "layout/MainLayout/Header/HeaderContent/Profile/index";
import Footer from "layout/MainLayout/Footer";
import { Outlet } from "react-router-dom";
import useConfig from "hooks/useConfig";
import { IconButton, useMediaQuery } from "@mui/material";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router-dom";

const appBar = {
  position: "fixed",
  color: "inherit",
  elevation: 0,
  sx: {
    borderBottom: `1px solid #F0F0F0`,
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
  },
};

function MainModuleLayout() {
  const { container, miniDrawer, menuOrientation } = useConfig();
  const navigate = useNavigate();

  const mainHeader = (
    <IconButton
      disableRipple
      aria-label="open drawer"
      onClick={() => {
        navigate("/modules");
      }}
      edge="start"
      color="secondary"
      sx={{
        color: "text.primary",
        bgcolor: "grey.100",
      }}
    >
      <HomeOutlinedIcon />
    </IconButton>
  );

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <AppBar {...appBar}>
        <Toolbar>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: "100%" }}
            spacing={1}
          >
            <img src={com} alt="Company" height="50px" />
            {mainHeader}
            <Box sx={{ width: "100%" }}></Box>
            <Profile />
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          ...(container && { px: { xs: 0, sm: 2 } }),
          position: "relative",
          minHeight: "calc(100vh - 40px)",
          mt: 5,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Toolbar />
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
}

export default MainModuleLayout;
